<template>
  <v-container v-if="project" class="mt-16">
    <info-project :init-project="project" />
  </v-container>
</template>

<script>
import { ref, onMounted, computed } from "@vue/composition-api";
import InfoProject from "@/components/company/project/InfoProject";

import api from "@/services";
import router from "@/router";

export default {
  name: "CompanyProjectDetail",
  components: {
    InfoProject,
  },
  setup() {
    const project = ref(null);

    const getProjectById = async ({ projectId }) => {
      try {
        const { data: response } = await api.getProjectById(projectId);
        const { data } = response;
        project.value = data;
      } catch (error) {}
    };

    onMounted(() => {
      const { id } = router.currentRoute.params;

      if (id) getProjectById({ projectId: id });
    });

    return {
      project,
    };
  },
};
</script>

<style lang="scss" scope></style>
